<template>
  <div>
    <template
      v-if="useDialog"
    >
      <v-dialog
        :value="value"
        @input="$emit('input', false)"
        max-width="1100"
      >
        <campaign-tracking-form
          :key="reloadKey"
          :is-loading="isLoading"
          :platforms="platforms"
          @submit="handleSubmit"
          is-dialog-view
        />
      </v-dialog>
    </template>

    <campaign-tracking-form
      v-else
      :is-loading="isLoading"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
// Import child components
const CampaignTrackingForm = () => import(/* webpackChunkName: "campaign-tracking-form" */ "@/components/crm/campaign-tracking/Form.vue")

// Export the SFC
export default {
  // Name of the SFC
  name: "CampaignTrackingCreate",

  // Register children components
  components: {
    CampaignTrackingForm
  },

  // Accept incoming data from parent
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    useDialog: {
      type: Boolean,
      default: false
    },

    returnAfterSubmit: {
      type: Boolean,
      default: false
    },

    platforms: {
      type: Array,
      default: () => []
    }
  },

  // Define local data variables
  data: () => ({
    // Whether we're making a network request or not
    isLoading: false,

    // The key to refresh child
    reloadKey: Symbol()
  }),

  // Define local method functions
  methods: {
    /**
     * Handle the submit event from child form view
     *
     * @returns {void}
     */
    async handleSubmit(formData) {
      // Otherwise, set a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isLoading = true

      // Make the network request
      try {
        // Get the response
        const response = await axios({
          url: "/api/campaign-tracking",
          method: "POST",
          data: formData
        })

        // Refresh the user's balance
        fetchProfile()

        // If we should return after submit
        if (this.returnAfterSubmit) {
          // Show a success toast
          this.$store.dispatch("toasts/add", { text: "Campaign created!" })

          // Emit the model
          this.$emit("created", response.data)

          // Reset the child
          this.reloadKey = Symbol()

          // Close the dialog
          this.$emit("input", false)
        }
        // Otherwise
        else {
          // Show a success toast
          this.$store.dispatch("toasts/add", { text: "Campaign created! Add your influencers now." })

          // Since it was successful, redirect the user to view the campaign
          this.$router.replace({
            name: "CampaignTrackingInfluencers",
            params: {
              uuid: response.data.uuid
            }
          })
        }
      } catch (error) {
        // Log the error
        logger({ type: "CampaignTracking/Create Error", error })

        // Show an error toast
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      } finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isLoading = false
      }
    }
  }
}
</script>
